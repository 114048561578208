<template>
  <div class="table-responsive">
    <table
      v-if="rows[0].length >= 3 || header"
      class="table table-striped jour-detail-table table-sm"
      :aria-label="title"
    >
      <thead>
        <tr>
          <th
            v-for="(row, index) in rows[0]"
            :key="index"
            :class="{ rightAligned : index > 0 && colsWithStrings.indexOf(index) === -1 }"
            v-html="row"
          />
        </tr>
      </thead>
      <tbody v-if="limit === -1">
        <tr
          v-for="(row, index) in rows.slice(1)"
          :key="index"
        >
          <td
            v-for="(col, index2) in row"
            :key="index2"
            :class="{ rightAligned : index2 > 0 && colsWithStrings.indexOf(index2) === -1 }"
          >
            <a
              v-if="col && col.url && col.attr"
              :href="col.url"
              :target="col.target === undefined ? defaultTarget : col.target"
              @click="leavingmsn(col)"
              v-bind="col.attr"
            >
              <span v-html="col.text" />
            </a>
            <a
              v-else-if="col && col.url"
              :href="col.url"
              :target="col.target === undefined ? defaultTarget : col.target"
              @click="leavingmsn(col)"
            >
              <span v-html="col.text" />
            </a>
            <RouterLink
              v-else-if="col && col.to"
              :to="col.to"
            >
              <span v-html="col.text" />
            </RouterLink>
            <span
              v-else
              v-html="col"
            />
          </td>
        </tr>
      </tbody>
      <tbody v-else-if="rows.length > 1">
        <tr
          v-for="(row, index) in rows.slice(1, limit + 1)"
          :key="index"
        >
          <td
            v-for="(col, index2) in row"
            :key="index2"
            :class="{ rightAligned : index2 > 0 && colsWithStrings.indexOf(index2) === -1 }"
          >
            <a
              v-if="col && col.url && col.attr"
              :href="col.url"
              :target="col.target === undefined ? defaultTarget : col.target"
              @click="leavingmsn(col)"
              v-bind="col.attr"
            >
              <span v-html="col.text" />
            </a>
            <a
              v-else-if="col && col.url"
              :href="col.url"
              :target="col.target === undefined ? defaultTarget : col.target"
              @click="leavingmsn(col)"
            >
              <span v-html="col.text" />
            </a>
            <RouterLink
              v-else-if="col && col.to"
              :to="col.to"
            >
              <span v-html="col.text" />
            </RouterLink>
            <span
              v-else
              v-html="col"
            />
          </td>
        </tr>
      </tbody>
    </table>

    <dl
      v-else
      :aria-label="title"
      :class="{ small : smTwoCol }"
    >
      <template
        v-for="(row, listIndex) in normalizedRows"
      >
        <dt
          v-if="typeof(row.label) === 'string'"
          :key="listIndex"
          v-html="row.label"
        />
        <dt
          v-else
          :key="listIndex"
          v-bind="row.label.attr"
          v-html="row.label.text"
        />
        <dd :key="(listIndex + 1) * normalizedRows.length">
          <ul
            v-if="row.isArray"
            :aria-label="`${row.label}`"
          >
            <li
              v-for="(item, itemIndex) in row.value"
              :key="itemIndex"
            >
              <a
                v-if="item.url"
                :href="item.url"
                :target="item.target ? item.target : defaultTarget"
                v-bind="item.attr"
                v-html="item.text"
                @click="leavingmsn(item)"
              />
              <RouterLink
                v-else-if="item && item.to"
                :to="item.to"
              >
                <span v-html="item.text" />
              </RouterLink>
              <span
                v-else
                v-html="item"
              />
            </li>
          </ul>
          <a
            v-else-if="row.isLink"
            :href="row.value.url"
            :target="row.value.target ? row.value.target : defaultTarget"
            :aria-label="`${row.label} ${row.value.text}`"
            v-html="row.value.text"
            @click="leavingmsn(row.value)"
          />
          <RouterLink
            v-else-if="row.value.to"
            :to="row.value.to"
          >
            <span v-html="row.value.text" />
          </RouterLink>
          <span
            v-else
            v-html="row.value"
          />
        </dd>
      </template>
    </dl>
  </div>
</template>
<script>
import { LeavingMSNAPI } from '@/api'

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    rows: {
      type: Array,
      required: true,
    },
    header: {
      type: Boolean,
      required: false,
      default: false,
    },
    classes: {
      type: String,
      required: false,
      default: '',
    },
    smTwoCol: {
      type: Boolean,
      required: false,
      default: false,
    },
    defaultTarget: {
      type: String,
      required: false,
      default: '_blank',
    },
    limit: {
      type: Number,
      required: false,
      default: -1,
    },
  },
  computed: {
    normalizedRows() {
      return this.rows.map(row => {
        const label = row[0]
        const value = row[1]
        let isLink = false
        const isArray = Array.isArray(value)

        if (!isArray && value.url) {
          isLink = true
        }

        return {
          label,
          value,
          isLink,
          isArray,
        }
      })
    },
    colsWithStrings: function() {
      // Want to ommit numbers that have been formatted as strings.
      const cols = []
      const lastRow = this.rows[this.rows.length - 1]
      for (const i in lastRow) {
        let content = lastRow[i]
        if (typeof content === 'object') {
          // Replace content with the text value
          if (content.text) {
            content = content.text
          } else {
            console.log('Unknown Content type:')
            console.log(content)
          }
        }
        if (typeof content === 'number') {
          continue // don't need to add it
        }
        if (typeof content === 'string') {
          // Check if it can be converted to a number
          const number = parseFloat(content.replaceAll(',', ''))
          if (isNaN(number)) {
            cols.push(parseInt(i))
          }
        }
      }
      return cols
    },
    instCode() {
      return this.$store.getters.appConfig.institution.code
    },
  },
  methods: {
    normalize(value) {
      if (!Array.isArray(value) && typeof value === 'object' && value.url) {
        // console.log([value])
        return [value]
      }

      return value
    },
    leavingmsn(col) {
      if (col.leavingmsn) {
        LeavingMSNAPI.leavingmsn(col.url, this.instCode)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
  .rightAligned {
    text-align: right;
  }

  table.table.jour-detail-table tr:first-child th, table.table.jour-detail-table tr:first-child td {
    border: none
  }

  dl {
    overflow: hidden;
    margin: 0;
    padding: 0;
    font-size: 1rem;

    dt, dd {
      float: left;
      margin: 0;
      padding: 12px;
      width: 50%;
    }
    dt:not(:first-child) {
      border-top: 1px solid rgba(0,0,0,.125);
    }
    dd:not(:nth-child(2)) {
      border-top: 1px solid rgba(0,0,0,.125);
    }

    &.small {
      dt, dd {
        padding: 5px;
      }
    }

    dt {
      clear: both;
      font-weight: bold;
    }

    dd {
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
      }
    }
  }

</style>
